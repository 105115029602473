<template>
    <el-scrollbar class="comp__container--absolute">
        <TextTitle titleName="个人信息"></TextTitle>
        <el-skeleton :rows="6" v-if="loading" />
        <div v-else class="main__body">
            <el-image :src="userInfo.base64" style="width: 8em; height: 8em; border-radius: 10px">
                <div slot="error">
                    <img style="width: 8em; height: 8em; border-radius: 10px" src="@/assets/logo_black.png">
                </div>
            </el-image>
            <div>
                <p class="one__line"><span class="title">中文名：</span>{{ userInfo.chinaname }}</p>
                <p class="one__line"><span class="title">用户id：</span>{{ userInfo.id }}
                    <span class="admin__blue" v-if="isAdmin"><i class="el-icon-s-custom"></i>管理员</span>
                    <span class="admin__blue" v-if="isSuperAdmin"><i class="el-icon-s-custom"></i>超级管理员</span>
                </p>
                <p class="one__line"><span class="title">账号：</span>{{ userInfo.name }}</p>
                <p class="one__line"><span class="title">邮件：</span>{{ userInfo.email || '-' }}</p>
                <p class="one__line"><span class="title">职位：</span>{{ userInfo.officium | filterRole }}</p>
                <p class="one__line"><span class="title">积分：</span>{{ userInfo.money || 0 }}</p>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import TextTitle from "@/components/TextTitle.vue";
import { getInfo } from "@/api/home";
export default {
    name: 'asgUserInfo',
    components: {
        TextTitle,
    },
    data() {
        return {
            userInfo: {},
            loading: false
        };
    },
    computed: {
        isAdmin() {
            return this.$store.state.adminRoles === '1' || sessionStorage.getItem('adminRoles') === '1'
        },
        isSuperAdmin() {
            return this.$store.state.adminRoles === '2' || sessionStorage.getItem('adminRoles') === '2';
        }
    },
    created() {
        this.loading = true;
        getInfo().then(res => {
            this.userInfo = res.data;
        }).finally(() => {
            this.$nextTick(() => {
                this.loading = false;
            })
        })
    },
}
</script>
<style lang='less' scoped>
.comp__container--absolute {
    width: 100%;
    height: 100%;

    .main__body {
        width: 90%;
        display: grid;
        grid-template-columns: 20% auto;
        margin: 12px;
        align-items: center;
        gap: 24px;

    }
}

.one__line {
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .title {
        font-weight: bold;
    }

    margin:0.3rem;
}

.admin__blue {
    color: #4090EF;
    margin-left: 3px;
}
</style>