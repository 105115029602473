<template>
    <el-dialog :visible="operationVisible" title="添加赛程" width="60%" @close="closeDialog">
        <el-form ref="form" :model="form" label-position="right" label-width="120px" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="所属赛季" prop="belong">
                        <el-select style="width:80%" size="small" v-model="form.belong" placeholder="请选择所属赛季">
                            <el-option v-for="item in eventOptions" :key="item.name" :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="赛程分类" prop="tag">
                        <el-select style="width:80%" size="small" v-model="form.tag" placeholder="请输入赛程标签">
                            <el-option v-for="(item, index) in tagOptions" :key="index" :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="主场战队名" prop="team1_name">
                        <!-- <el-select size="small" v-model="team1_name" clearable filterable placeholder="请填写主场战队名">
                      <el-option
                        v-for="item in gameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select> -->
                        <el-input style="width:80%" size="small" v-model="form.team1_name" placeholder="请填写主场战队名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="客场战队名" prop="team2_name">
                        <!--<el-select size="small" v-model="team2_name" clearable filterable placeholder="请填写客场战队名">
                      <el-option
                        v-for="item in gameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select> -->
                        <el-input style="width:80%" size="small" v-model="form.team2_name" placeholder="请填写客场战队名"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="比赛开始时间" prop="opentime">
                        <el-date-picker style="width:80%" v-model="form.opentime" size="small" type="datetime" placeholder="选择日期时间"
                            value-format="yyyy-MM-ddThh:mm:ss.656Z" format="yyyy-MM-dd hh:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="导播" prop="referee">
                        <el-select style="width:80%" size="small" v-model="form.referee" placeholder="请选择导播">
                            <el-option v-for="item in instructor" :key="item.chinaname" :label="item.chinaname"
                                :value="item.chinaname">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="解说1">
                        <el-select style="width:80%" size="small" filterable clearable value-key="id" v-model="commentary_value[0]"
                            placeholder="请选择解说1">
                            <el-option v-for="item in commentary" :key="item.chinaname" :label="item.chinaname"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="解说2">
                        <el-select style="width:80%" size="small" filterable clearable value-key="id" v-model="commentary_value[1]"
                            placeholder="请选择解说2">
                            <el-option v-for="item in commentary" :key="item.chinaname" :label="item.chinaname"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <footer>
                <el-button type="primary" size="small" v-loading="btnloading" element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)" @click.native="submit">
                    发布
                </el-button>
                <el-button size="small" @click="closeDialog">
                    关闭
                </el-button>
            </footer>
        </el-form>
    </el-dialog>
</template>

<script>
import { pushSchedule } from "@/api/schedule/index.js"
export default {
    name: 'addSchedule',
    props: {
        operationVisible: {
            type: Boolean,
            default: false
        },
        eventOptions: {
            type: Array,
            default: () => []
        },
        instructor: {
            type: Array,
            default: () => []
        },
        commentary: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            form: {
                belong: '',
                tag: '',
                opentime: '',
                team1_name: '',
                team2_name: '',
                referee: '待定'
            },
            commentary_value: [{ id: 0, chinaname: '待定' }, { id: 0, chinanme: '待定' }],
            tagOptions: [
                { name: "总决赛" },
                { name: "季军赛" },
                { name: "半决赛" },
                { name: "四强赛" },
                { name: "八强赛" },
                { name: "十六强" },
                { name: "常规赛" },
                { name: "海选赛" },
                { name: "高校赛" },
                { name: "众创赛" },
                { name: "神将赛" },
                { name: "淘汰赛" },
                { name: "擂台赛" },
            ],
            btnloading: false,
            rules: {
                belong: [
                    { required: true, message: '请选择赛季', trigger: 'change' },
                ],
                tag: [
                    { required: true, message: '请选择赛程标签', trigger: 'change' }
                ],
                opentime: [
                    { required: true, message: '请选择比赛开始时间', trigger: 'change' },
                ],
                team1_name: [
                    { required: true, message: '请输入主场战队', trigger: 'blur' }
                ],
                team2_name: [
                    { required: true, message: '请输入客场战队', trigger: 'blur' }
                ],
                referee: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('update:operationVisible', false);
        },
        async submit() {
            try {
                const valid = await this.$refs.form.validate();
                if (!valid) return;
                const comMap = this.commentary_value.map(com => ({ id: com.id, chinaname: com.chinaname }));
                const filterArr = comMap.filter(f => f.id !== 0);
                let commentary = JSON.stringify(filterArr);
                this.btnloading = true;
                const requestParams = {
                    ...this.form,
                    commentary
                }
                const { data, status } = await pushSchedule(requestParams);
                if (status !== 200) throw new Error(data.message);
                this.onSuccess();
            } catch (error) {
                if (error instanceof Object && !'message' in error) {
                    return this.$message.error('请完整填写表单');
                }
                if (error.response.data.code === 400) {
                    return this.$message.error('操作失败，无权访问');
                }
                this.$message.error('操作失败，后端服务器异常');
            } finally {
                this.btnloading = false;
            }
        },
        onSuccess() {
            let successBelong = this.form.belong;
            this.form = {
                belong: '',
                tag: '',
                opentime: '',
                team1_name: '',
                team2_name: '',
                referee: '待定'
            },
                this.commentary_value = [{ id: 0, chinaname: '待定' }, { id: 0, chinanme: '待定' }],
                this.$message.success('操作成功!');
            console.log(successBelong, 'successBelong');
            this.$emit('onSuccess', successBelong);
        }
    },
    created() {

    },
}
</script>
<style lang='less' scoped>
footer {
    text-align: center;
    margin-top: 12px;
}
</style>