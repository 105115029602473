import { render, staticRenderFns } from "./asg-my-collaction.vue?vue&type=template&id=418e353c&scoped=true"
import script from "./asg-my-collaction.vue?vue&type=script&lang=js"
export * from "./asg-my-collaction.vue?vue&type=script&lang=js"
import style0 from "./asg-my-collaction.vue?vue&type=style&index=0&id=418e353c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418e353c",
  null
  
)

export default component.exports