<template>
    <div>
        <header class="workflowHeader">
            <div class="left__container">
                <el-image :src="userAvatar" style="width: 50px; height: 50px;margin-right: 16px; border-radius: 6px;">
                    <div slot="error">
                        <img style="width:50px;height:50px" src="@/assets/logo_black.png">
                    </div>
                </el-image>
                <!-- <img :src="userAvatar"> -->
                <div class="text--content">
                    <p class="welcome">
                        您好，{{ myUser }}
                        <span class="identify__content">
                            {{ isSuperAdmin ? '统领管理员' : isAdmin ? '领导管理员' : '普通人员' }}
                        </span>
                    </p>
                    <p class="role">{{ officium | filterRole }}</p>
                </div>
            </div>
            <div class="right__container">
                <img src="@/assets/person.png">
                <p>我经常发现，快乐几乎常常是辛勤工作的一种反应！</p>
            </div>
        </header>
        <div class="empty-consult" v-if="showEmpty && !design">
            <el-result icon="warning" title="未设计首页提示" subTitle="尊敬的用户，您尚未自定义首页，请前往个性化工作台设置。">
                <template slot="extra">
                    <el-button type="primary" size="medium"
                        @click="$router.push({ path: '/myCustomWorker' })">点我前往</el-button>
                </template>
            </el-result>
        </div>
        <template v-else>
            <div class="open-flow-intro" v-if="design">
                <div v-show="isOpenFlow === '1'" class="delete__icon" @click="isOpenFlow = '0'">
                    <i class="el-icon-delete-solid"></i>
                </div>
                <p v-show="isOpenFlow === '0'" @click="isOpenFlow = '1'">点击开启流程图</p>
                <asgFlowIntro v-show="isOpenFlow === '1'"></asgFlowIntro>
            </div>
            <asgFlowIntro v-show="isOpenFlow === '1'" v-else></asgFlowIntro>
            <div class="main__container" :style="`grid-template-columns':'repeat(${settingForm.columns},1fr)`">
                <li v-for="(item, index) in componentList" :style="{
                    'background': !!item.component ? '#fff' : '#acd3fa',
                    'height': (item.height ? item.height : settingForm.height) + 'px',
                    'flex-direction': design ? 'column' : 'row'
                }" class="box-li" data-drop="copy" :data-index="index" :key="index">
                    <el-row style="margin:6px 0;" type="flex" justify="start" align="middle"
                        v-show="design && item.component">
                        <el-col :span="8">
                            盒子高度
                        </el-col>
                        <el-col :span="10" :offset="2">
                            <el-input-number @change="heightChange" size="small" v-model="item.height" :min="250"
                                :max="500" :step="50" label="请输入高度"></el-input-number>
                        </el-col>
                    </el-row>


                    <div v-show="!!item.component && design" class="delete__icon" @click="deleteItem(index)">
                        <i class="el-icon-delete-solid"></i>
                    </div>
                    <i v-show="!item.component && design" class="el-icon-minus trem" @click="deletePort(index)"></i>
                    <component :is="item.component" :design="design"></component>
                </li>
                <div v-if="design" class="addList__icon" @click="addPort">
                    <i class="el-icon-plus"></i>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import asgMyCollaction from './asg-my-collaction.vue';
import asgUserInfo from './asg-user-Info.vue';
import asgQuickJump from './asg-quick-jump.vue';
import asgMyShopping from './asg-my-shopping.vue';
import asgMyGuess from './asg-my-guess.vue';
import asgOtherSystem from './asg-other-system.vue';
import asgFlowIntro from "./asg-flow-intro.vue";
import asgMyTeam from './asg-my-team.vue';
export default {
    name: 'HomeDesign',
    components: {
        asgMyCollaction,
        asgUserInfo,
        asgQuickJump,
        asgMyShopping,
        asgMyGuess,
        asgOtherSystem,
        asgFlowIntro,
        asgMyTeam
    },
    props: {
        settingForm: {
            type: Object,
            default: () => { },
        },
        changeCount: {
            type: Number,
            default: 0
        },
        design: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            componentList: [],
            showEmpty: false,
            customSetting: {},
            isOpenFlow: '0'
        };
    },
    computed: {
        myUser() {
            return this.$store.state.userInfo.chinaname || sessionStorage.getItem('chinaname');
        },
        userAvatar() {
            return this.$store.state.userInfo.base64 || sessionStorage.getItem("baseImg")
        },
        isAdmin() {
            return this.$store.state.adminRoles === '1' || sessionStorage.getItem('adminRoles') === '1'
        },
        isSuperAdmin() {
            return this.$store.state.adminRoles === '2' || sessionStorage.getItem('adminRoles') === '2';
        },
        officium() {
            return this.$store.state.officium || sessionStorage.getItem('officium');
        },
    },
    watch: {
        changeCount() {
            this.setColumns();
        }
    },
    created() {
        try {
            if (!window.localStorage.getItem('customWorker-asg') || Object.keys(this.settingForm).length === 0) {
                this.showEmpty = true;
                return;
            }
            const json = JSON.parse(window.localStorage.getItem('customWorker-asg'));
            this.isOpenFlow = json.isOpenFlow ?? '0';
            if (!!json.componentList && json.componentList.length > 0) {
                this.componentList = json.componentList.filter(item => item !== null);
            }
        } catch (error) {
            console.log(error.message);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setColumns();
        })
    },
    methods: {
        heightChange() {
            this.$forceUpdate();
        },
        deletePort(index) {
            this.componentList.splice(index, 1);
        },
        addPort() {
            this.componentList.push({});
        },
        deleteItem(index) {
            this.componentList[index] = {};
            this.$forceUpdate();
        },
        setComponent(index, type) {
            console.log(index, type);
            this.componentList[index].component = type;
            this.$forceUpdate();
        },
        setColumns() {
            const dom = document.querySelector('.main__container');
            dom.style.gridTemplateColumns = `repeat(${this.settingForm.column},1fr)`;
            const liList = document.querySelectorAll('.box-li');
            const borderHeight = (this.settingForm.height - this.settingForm.padding * 2) + 'px';
            if (this.design) {
                const addIcon = document.querySelector('.addList__icon');
                addIcon.style.height = borderHeight;
            }
            liList.forEach(li => {
                li.style.padding = this.settingForm.padding + 'px';
            })
        },
    },
}
</script>
<style lang='less' scoped>
.workflowHeader {
    height: 56px;
    width: calc(100% - 30px);
    background: linear-gradient(90deg, #f1f5fd 16%, #c9d6f8 93%);
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #a0b7f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-radius: 3px;
    margin-bottom: 32px;

    .left__container {
        display: flex;
        align-items: center;

        // img {
        //     width: 50px;
        //     height: 50px;
        //     border-radius: 7px;
        //     margin-right: 16px;
        // }

        .welcome {
            font-weight: 600;

            .identify__content {
                display: inline-block;
                background: #D6E5F4;
                color: #0C80E5;
                font-size: 12px;
                padding: 3px 8px;
                font-weight: 500;
                border-radius: 2px;
                margin-left: 12px;
            }
        }

        .text--content {
            .role {
                color: #9BA4AD;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .right__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            position: relative;
            width: 96px;
            height: 90px;
            top: -6px;
            margin-right: 15px;
        }

        p {
            font-size: 17px;
        }
    }
}

.main__container {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    li {
        background-color: #acd3fa;
        display: flex;
        height: 220px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 8px 0px #DCE7F1;
        position: relative;
        border-radius: 10px;

        .delete__icon {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 40px;
            height: 40px;
            background: #f40;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background: lighten(#f40, 20%);
            }

            i {
                font-size: 24px;
                color: #fff;
            }
        }

        &.drop-over {
            background: #303030 !important;
        }

        .tag {
            background: #fff;
            height: 50px;
            width: 100px;
        }
    }

    .addList__icon {
        cursor: pointer;
        width: 100%;
        border: 2px dashed #969696;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        &:hover {
            background: lighten(#409EFF, 20%);

            i {
                color: #409EFF;
            }
        }

        i {
            font-size: 3rem;
            color: #969696;
        }
    }
}

.trem {
    color: #969696;
    font-size: 3rem !important;
    cursor: pointer;
}

.empty-consult {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(80vh - 40px - 80px - 60px - 50px);
}

// 是否开启流程
.open-flow-intro {
    min-height: 80px;
    width: calc(100% - 24px);
    margin-right: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #e7e7e7;
    margin-bottom: 12px;
    position: relative;

    .delete__icon {
        position: absolute;
        top: -15px;
        right: -15px;
        width: 40px;
        height: 40px;
        background: #f40;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background: lighten(#f40, 20%);
        }

        i {
            font-size: 24px;
            color: #fff;
        }
    }

    p {
        cursor: pointer;

        &:hover {
            color: #4090EF;
        }
    }
}
</style>